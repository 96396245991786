<template>
 <CCard>
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right"  color="" @click="navToNew">New Brief Topic</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
     striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       
      pagination>
        <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
       <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
       <template #controlMeasures="{item}">
        <td class='font-weight-bold'
          v-if="item.controlMeasures"
        >{{ item.controlMeasures }}</td>
        <td v-else>--</td>
      </template>
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'Topic', params: {topicId: item.topicId, mode: 'view'}}">View </CDropdownItem>
           
                <!-- <CDropdownItem :to="{name: 'Departments', params: {groupId: item.groupId, mode: 'view'}}">Departments</CDropdownItem> -->
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deletetopic(item.topicId)"
                    ></ConfirmationModal>

                </CDropdown>

        
        </td>
      </template>
      
      </CDataTable>
  </CCardBody>
   <CCardFooter align="right">
        
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
   <div ref="modalArea"></div>
 </CCard>
</template>

<script>
const fields = [
   { key: "index", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "topicName", _classes: 'font-weight-bold', _style: "min-width:200px" },
    { key: "controlMeasures", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "createdTime",_classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
import Vue from "vue";
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Topics",
  components: {
     ConfirmationModal,
   },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getTopic() {
       this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/topics/"+ this.$route.params.briefId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
     deletetopic(topicId) {
      console.log("delete called with", this.topicId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/delete/topic/" + topicId)
        .then((response) => response.json())
        .then((data) => {
          this.getTopic();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
         window.location.reload();
        });
    },
    navToNew(){
       this.$router.push({ name: "NewTopic", params: {briefId:this.$route.params.briefId,mode: "new" } });
    },
     cancel() {
       window.history.back();
    },
  },
  mounted() {
    this.getTopic();
  },
};
</script>